tr,
td {
  text-align: center;
}

.green-row {
  color: #28a745 !important;
}

.red-row {
  color: #dc3545 !important;
}

.input {
  border-color: #007bff;
}

.input-date {
  max-width: 220px;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.gap-x {
  column-gap: 2rem;
}

.form{
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}