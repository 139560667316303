body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

tr,
td {
  text-align: center;
}

.green-row {
  color: #28a745 !important;
}

.red-row {
  color: #dc3545 !important;
}

.input {
  border-color: #007bff;
}

.input-date {
  max-width: 220px;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.gap-x {
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.form{
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}
.invalid_format {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
